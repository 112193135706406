window.requestAF = (function(){
			return  window.requestAnimationFrame       ||
		        	window.webkitRequestAnimationFrame ||
		        	window.mozRequestAnimationFrame    ||
		        	function(callback){return window.setTimeout(callback, 17)}
			})();

window.cancelAF = (function(){
			return  window.cancelAnimationFrame       ||
		        	window.webkitCancelAnimationFrame ||
		        	window.mozCancelAnimationFrame    ||
		        	function(id){return window.clearTimeout(id)}
			})();

if(!window.scope) window.scope = {};
if(window.jQuery){
	var oldOn = jQuery.fn.on, oldOff = jQuery.fn.off;
	//
	jQuery.fn._custmData = {};
	jQuery.fn.on = function(a,b,c,d,e){
		if(a === 'touchend'){
			var i, f;
			for (i = arguments.length - 1; i >= 0; i--) {
				if(arguments[i] && typeof arguments[i] === 'function'){
					f = arguments[i];
					arguments[i] = function(x){x.stopPropagation(); x.preventDefault(); f(x)};
					this._custmData[f.name] = arguments[i];
					oldOn.apply(this,arguments);
					break;
				}
			};
			return oldOn.apply(this,['mouseup',b,c,d,e]);
		} else {
			return oldOn.apply(this,[a,b,c,d,e]);
		}
	};
	//
	jQuery.fn.off = function(a,b,c){
		if(a === 'touchend'){
			var i, f;
			for (i = arguments.length - 1; i >= 0; i--) {
				if(arguments[i] && typeof arguments[i] === 'function'){
					var n = arguments[i].name;
					arguments[i] = this._custmData[n];
					delete this._custmData[n];
					oldOff.apply(this,arguments);
					break;
				}
			};
			return oldOff.apply(this,['mouseup',b,c]);
		} else {
			return oldOff.apply(this,[a,b,c]);
		}
	};
};
/* ********* scope.NewComponent **************
* returns base component element for non jquery based elements
* dependencies: Tweenlite for css
*/
(function() {
	"use strict";
	var td = (function(){
			return (('ontouchstart' in window)
			      || (navigator.maxTouchPoints > 0)
			      || (navigator.msMaxTouchPoints > 0));
			})(),
		down = "onpointerdown" in document.documentElement ? "pointerdown" : undefined,
		up = "onpointerup" in document.documentElement ? "pointerup" : undefined,
		move = "onpointermove" in document.documentElement ? "pointermove" : undefined;
	//
	if(!down) down = td?'touchstart':'mousedown';
	if(!up) up = td?'touchend':'mouseup';
	if(!move) move = td?'touchmove':'mousemove';	
	//
	scope.DOWN = down;
	scope.UP = up;
	scope.MOVE = move;
	scope.isMobile = void 0;
	//
    !function(t) {
        var e, n, i, o, s, a;
        return i = null, e = /WebKit\/([\d.]+)/, n = {
            Android: /(Android)\s+([\d.]+)/,
            ipad: /(iPad).*OS\s([\d_]+)/,
            iphone: /(iPhone\sOS)\s([\d_]+)/,
            Blackberry: /(BlackBerry|BB10|Playbook).*Version\/([\d.]+)/,
            FirefoxOS: /(Mozilla).*Mobile[^\/]*\/([\d\.]*)/,
            webOS: /(webOS|hpwOS)[\s\/]([\d.]+)/
        }, t.isMobile = function() {
            return this.environment(), i.isMobile;
        }, t.environment = function() {
            var t, e;
            return i || (e = navigator.userAgent, t = s(e), i = {
                browser: o(e),
                isMobile: !!t,
                isTouchDevice: (function(){
					return (('ontouchstart' in window)
					      || (navigator.maxTouchPoints > 0)
					      || (navigator.msMaxTouchPoints > 0));
					})(),
                screen: a(),
                os: t
            }), i;
        }, o = function(t) {
            var n;
            return n = t.match(e), n ? n[0] : t;
        }, s = function(t) {
            var e, i, o;
            for (i in n) if (o = t.match(n[i])) {
                e = {
                    name: "iphone" === i || "ipad" === i || "ipod" === i ? "ios" : i,
                    version: o[2].replace("_", ".")
                };
                break;
            }
            return e;
        }, a = function() {
            return {
                width: window.innerWidth,
                height: window.innerHeight
            };
        };
    }(scope);
    //
	window.scope.NewComponent = function(tag, attrObj, isSVG){

		var el = isSVG ? document.createElementNS('http://www.w3.org/2000/svg', tag) : document.createElement(tag);
		for(var a in attrObj) el.setAttribute(a,attrObj[a]);
		el._data = {};
		el._data.ceh = {};
		el.css = function(arg1, arg2){
			if(arg2 && arg2){
				this.style[arg1] = arg2;
				return this;
			} else {
				if(typeof arg1 === 'string'){
					var p = this.style[arg1];
					if(p==='' || p===undefined) p = window.getComputedStyle(this,null).getPropertyValue(arg1);
					return p;
				} else {
					TweenLite.set(this, arg1);
		 			return this;
				}
			}
		}
		// args: event [,optional: data to be passed], handler
		el.on = function(e,a1,a2){
			if(a2){
				var	t = a2.name,
					f = e === 'touchend'? function(e){ e.preventDefault(); e.data = a1; a2(e)} : function(e){ e.data = a1; a2(e)};
				this._data.ceh[t] = f;
				this.addEventListener(e,f);
				if(e === 'touchend') this.addEventListener('mouseup',f);
			} else {
				if(e === 'touchend'){
					var	t = a1.name,
						f = function(e){ e.preventDefault(); a1(e)};
					this._data.ceh[t] = f;	
					this.addEventListener(e,f);
					this.addEventListener('mouseup',a1);
				} else {
					this.addEventListener(e,a1);
				}
			}
	 		return this;
		}
		el.off = function(e,h){
			var t = h.name;
			if(this._data.ceh[t]){
				this.removeEventListener(e,this._data.ceh[t]);
				if(e === 'touchend') this.removeEventListener('mouseup',this._data.ceh[t]);
				delete this._data.ceh[t];
			} else {
				this.removeEventListener(e,h);
				if(e === 'touchend') this.removeEventListener('mouseup',h);
			}
	 		return this;
		}
		el.attr = function(arg1,arg2){
			if(typeof arg1 === 'string'){
				if(arg2) this.setAttribute(arg1,arg2);
				else return this.getAttribute(arg1);
			} else {
				for(var a in arg1) this.setAttribute(a,arg1[a]);
			}
			return this;
		}
		el.removeAttr = function(arg1){
			if(typeof arg1 === 'string'){
				this.removeAttribute(arg1);
			} else {
				for(var a in arg1) this.removeAttribute(a,arg1[a]);
			}
			return this;
		}
		el.show = function(){
			showEl(this);
			return this;
		}
		el.hide = function(){
	        hideEl(this);
			return this;
		}
		el.toggleShow = function(){
			if(this.style.display === 'none' || window.getComputedStyle(this,null).getPropertyValue('display') === 'none') showEl(this); else hideEl(this);
			return this;
		}
		el.remove = function(){
			this.parent().removeChild(this);
			return this;
		}
		el.parent = function(){
			return this.parentNode || this.parentElement;
		}
		el.addClass = function(str){
			addClass(this,str);
			return this;
		}
		el.removeClass = function(str){
			removeClass(this,str);
			return this;
		}
		if(!isSVG && tag !== 'canvas' && tag !== 'video') el.height = function(val){
			if(val){
				el.css({height:val});
				return this
			} else {
				return el.clientHeight;
			} 
		}
		if(!isSVG && tag !== 'canvas' && tag !== 'video') el.width = function(val){
			if(val){
				el.css({width:val});
				return this
			} else {
				return el.clientWidth;
			}
		}
		el.data = function(str,value){
			if(str && value){
				setData(this,str,value);
			} else {
				return getData(this,str);
			}
			return this;
			
		}
		return el;
	};

	function getData(el,attr){
		if(el.dataset !== undefined) return el.dataset[attr];
		else return el.getAttribute('data-'+attr);
	};

	function setData(el, attr, val){
		if(el.dataset !== undefined) el.dataset[attr] = val;
		else el.setAttribute('data-'+attr,val);
	};

	function addClass(el,str){
		var arr = el.className.split[' '], d = true;
		if(arr) for (var i = 0; i < arr.length; i++) {
			if(arr[i] === str) d = false;
		};
		if(d) el.className = el.className.concat(' '+str);
	};

	function removeClass(el,str){
		var arr = el.className.split[' '], d;
		if(arr) for (var i = 0; i < arr.length; i++) {
			if(arr[i] === str){
				d = i;
				break;
			}
		};
		if(d){ 
			arr.splice(d, 1);
			el.className = arr.toString().replace(/,/g,' ');
		};
	};

	function showEl(el){
		el.style.display = el._data['olddisplay'] || ''; 
        // find default display
        if(!el._data['olddisplay']){
        	var d = window.getComputedStyle(el,null).getPropertyValue('display');
        	if (d === 'none') {
	        	var selectors = [el.tagName.toLowerCase()], cn = el.className.split(' ');
	        	for (var c = 0; c < cn.length; c++) cn[c] = '.'.concat(cn[c]);
	        	if(cn[0] !== '' ) selectors = selectors.concat(cn);
	        	if(el.id !== '') selectors.push('#'+el.id);
	        	for(var i=0; i<document.styleSheets.length; i++) {
				    var sheet = document.styleSheets[i];
				    if(sheet.rules) for (var j = 0; j < sheet.rules.length; j++) {
				    	for (var n = 0; n < selectors.length; n++) {
				    		if(sheet.rules[j].selectorText) {
				    			var arr = sheet.rules[j].selectorText.split(',');
					    		for (var a = 0; a < arr.length; a++) {
					    			if(arr[a] === selectors[n]){
					    				if(sheet.rules[j].style.display==='none'){
					    					sheet.rules[j].style.display = '';
					    					el._data['olddisplay'] = window.getComputedStyle(el,null).getPropertyValue('display');
					    					sheet.rules[j].style.display = 'none';
					    					//console.log(sheet.rules[j]);
					    				} 
					    				
					    			} 
					    		}
				    		}
				    	}
				    	
				    }
				}
	        } else {
	        	el._data['olddisplay'] = d;
	        }
            el.style.display = el._data['olddisplay'] || '';
        }
        return el.style.display;
	};

	function hideEl(el){
        if (!el._data['olddisplay']) {
        	var d = el.style.display!=='' ? el.style.display : window.getComputedStyle(el,null).getPropertyValue('display');
            if(d !== 'none') el._data['olddisplay'] = d;
        }
		return el.style.display = 'none';
	};

}());

/* ********* scope.UIBtn **************
* returns ui button
* dependencies: Tweenlite
*/
(function() {
	"use strict";
 	window.scope.UIBtn = function(text,href,icon,color,inDiv,isOff) 
 	{
 		var uibtn = scope.NewComponent(inDiv && inDiv===true?'div':'button'),
 			bColor = color?color:'white',
 			bIcon = icon?icon:'none',
 			cssVars = {transformOrigin:"center center"};

 		// props & methods
 		uibtn.componentType = 'UIBtn';
 		uibtn.isOn = true;
 		uibtn.oldHide = uibtn.hide;
 		uibtn.oldShow = uibtn.show;
 		uibtn.oldToggleShow = uibtn.toggleShow;
 		uibtn.oldRemove = uibtn.remove;

	 	uibtn.remove = function(withFade)
	 	{
	 		this.isOn = false;
	 		if(withFade && withFade === true){
	 			//var context = this;
	 			TweenLite.to(this,.4,{opacity:0, scaleX:.9, scaleY:.9, ease: Back.easeIn, onComplete: this.oldRemove, onCompleteScope:this});
	 		} else {
	 			this.oldRemove();
	 		}
	 		return this;
	 	}

	 	uibtn.show = function(delay)
	 	{	
	 		this.removeAttribute('disabled');
	 		this.oldShow();
	 		TweenLite.to(this,.4,{ opacity:1, delay:delay || 0, ease: Quad.easeOut});
	 		TweenLite.to(this,.5,{ scaleX:1, scaleY:1, delay:delay || 0, ease: Back.easeOut, overwrite:0});
	 		this.isOn = true;
	 		return this;
	 	}

	 	uibtn.hide = function(dontHide)
	 	{
	 		//var context = this;
	 		TweenLite.to(this,.4,{opacity:0, scaleX:.85, scaleY:.85, ease: Back.easeIn, 
	 			onComplete: function(){
	 				if(!dontHide) this.target.oldHide();
	 			}});
	 		this.isOn = false;
	 		return this;
	 	}

	 	uibtn.toggleShow = function(dontHide)
	 	{	
	 		if(this.isOn) this.hide(dontHide); else this.show();
	 		return this;
	 	}

	 	uibtn.dim = function(alpha,scale)
	 	{
	 		alpha = alpha?alpha:.5;
	 		scale = scale?scale:1;
	 		this.setAttribute('disabled','disabled');
	 		TweenLite.to(this,.2,{opacity:alpha, scaleX:scale, scaleY:scale});
	 		this.isOn = false;
	 		return this;
	 	}

	 	uibtn.text = function(str)
	 	{
	 		var c;
	 		if(this.tagName === 'DIV'){
	 			c = this.children[0].children[0].className;
	 			this.children[0].innerHTML = '<span class="'+c+'"></span>'+str;
	 		} else  {
	 			c = this.children[0].className;
	 			this.innerHTML = '<span class="'+c+'"></span>'+str;
	 		}
	 		return this;
	 	}

	 	uibtn.icon = function(str)
	 	{
	 		var span = this.tagName === 'DIV' ? this.children[0].children[0] : this.children[0];
	 		span.className = (str==="none"?'':'btn-icon-sprite btn-icon-'+str);
	 		return this;
	 	}

	 	uibtn.color = function(str)
	 	{
	 		if(this.tagName === 'DIV') this.children[0].className = 'act-btn act-btn-'+str;
	 		else this.className = 'act-btn act-btn-'+str;
	 		return this;
	 	}

	 	uibtn.href = function(str)
	 	{
	 		if(str){
	 			this._href = function(){window.location.href = str};
	 			this.on(scope.UP, this._href);
	 		} else if (str === null && this._href){
	 			this.off(scope.UP, this._href);
	 			this._href = undefined;
	 		}
	 		return this;
	 	}

	 	if(inDiv && inDiv === true){
 			uibtn.className = 'act-btn-div';
 			var btn = document.createElement('button');
 			btn.className = 'act-btn act-btn-'+bColor;
 			btn.innerHTML = '<span class="'+(bIcon==="none"?'':'btn-icon-sprite btn-icon-'+bIcon)+'"></span>'+text;
 			uibtn.appendChild(btn)
 		} else {
 			uibtn.className = 'act-btn act-btn-'+bColor;
 			uibtn.innerHTML = '<span class="'+(bIcon==="none"?'':'btn-icon-sprite btn-icon-'+bIcon)+'"></span>'+text;
 		}
 		
 		if(isOff && isOff === true){
 			cssVars.opacity = 0;
 			cssVars.scaleX = .85;
 			cssVars.scaleY = .85;
 			uibtn.oldHide();
 			uibtn.isOn = false;
 		};
 		uibtn.css(cssVars);
 		if(href) uibtn.href(href);
	 	return uibtn;
	}; 	

}());

/* ********* scope.LoadAnim **************
* canvas object
* dependencies: TweenLite, TimelineLite
*/
(function() {
	"use strict";
	//default rgbArray is [0,0,0] (black).
 	window.scope.LoadAnim = function(rgbArray) 
 	{
 		var cvs = scope.NewComponent('canvas',{class:'act-load-anim', width:146, height:32}), 
 			i, context = cvs;
 		if(!rgbArray) rgbArray = [0,0,0];
 		// props
 		cvs.componentType = 'LoadAnim';
 		cvs.ctx2D = cvs.getContext("2d");
 		cvs.ctx2D.globalAlpha = 0;
 		cvs.dotArray = [];
 		cvs.numDots = 5;
		cvs.alphaMin = .6;
		cvs.alphaMax = 1;
		cvs.oldShow = cvs.show;
		cvs.oldHide = cvs.hide;
		cvs.ticker = undefined;
		cvs.sequence = new TimelineLite({onComplete:reverse, onCompleteParams:["{self}"] ,onReverseComplete:restart, onReverseCompleteParams:["{self}"], paused:true});
		// methods
		cvs.show =  function()
		{
			this.oldShow();
			this.ticker = requestAF( function(){ onTick(context) } );
			this.sequence.play();
			TweenLite.to(this.ctx2D,.6,{globalAlpha:1, ease:Sine.easeOut});
			return this;
		}
		cvs.hide = function (remove)
		{
			var ctx = this;
			TweenLite.to(this.ctx2D,.6,{globalAlpha:0, ease:Sine.easeOut, onCompleteScope:ctx, 
				onComplete:function(){
					this.sequence.pause(); 
					this.ticker = cancelAF(this.ticker);
					this.oldHide();
					if(remove) this.remove();
				}
			});
			return this;
		}
 		// create dots
 		for (i = 0; i < cvs.numDots; i++) 
		{
			var dot;
			if(i == 0){
				dot = new Dot(3,3,1,1)
			} else {
				dot = new Dot((28*i)+3,3,cvs.alphaMin,.8)
			};
			dot.fillColor = "rgba("+rgbArray.toString()+",alpha)"; 
			dot.draw(cvs.ctx2D);
			cvs.dotArray.push(dot);
		}
		// write sequence
		for (i = 0; i < cvs.dotArray.length; i++) 
		{
			if(i == 0)
			{
				cvs.sequence.append(TweenLite.to(cvs.dotArray[i],.2,{alpha:cvs.alphaMin, scale:.8, ease:Sine.easeOut })  );
			}
			else if(i == cvs.dotArray.length-1)
			{
				cvs.sequence.append(TweenLite.to(cvs.dotArray[i],.2,{alpha:cvs.alphaMax, scale:1, ease:Sine.easeOut}), -.24);
			}
			else
			{
				cvs.sequence.append(TweenLite.to(cvs.dotArray[i],.2,{alpha:cvs.alphaMax, scale:1, ease:Sine.easeOut}), -.24);
				cvs.sequence.append(TweenLite.to(cvs.dotArray[i],.2,{alpha:cvs.alphaMin, scale:.8, ease:Sine.easeOut, delay:.2}), -.24);
			};
		}
		return cvs;
	};

	function onTick (context)
	{
		context.ctx2D.clearRect(0,0,146,32);
		for (var i = 0; i < context.numDots; i++) {
			context.dotArray[i].draw(context.ctx2D);
		};
		context.ticker = requestAF( function(){ onTick(context) } );
	};

	function reverse(sequence)
	{
		sequence.reverse();	
	};
		
	function restart(sequence)
	{
		sequence.restart();	
	};

	Dot.prototype.fillColor = "rgba(0, 0, 0, alpha)";

	function Dot(posX, posY, alpha, scale)
	{
		this.x = posX;
		this.y = posY;
		this.alpha = alpha;
		this.scale = scale;
		this.radius = 14;
	};

	Dot.prototype.draw = function(ctx2D)
	{
		ctx2D.fillStyle = this.fillColor.replace("alpha", (this.alpha).toString());
		ctx2D.beginPath();
		ctx2D.arc(this.x + this.radius, this.y + this.radius, this.radius * this.scale, 0, 2*Math.PI, false);
		ctx2D.fill();
		ctx2D.closePath();
	};

}());

/* ********* scope.VideoBtn **************
*  returns VideoBtn with css background image
* dependencies: TweenLite
*/
(function() {
	"use strict";
	/* params
	*  scale:Number = 1; (set custom size of VideoBtn. Inner divs only)
	*/
 	window.scope.VideoBtn = function(scale) 
 	{
 		var el = scope.NewComponent('div');
 		el.componentType = 'VideoBtn';
 		el.className = 'act-video-btn-div pointer';
 		el.pauseDiv = scope.NewComponent('div');
 		el.pauseDiv.className = 'act-video-btn act-pause-btn';
 		el.playDiv = scope.NewComponent('div');
 		el.playDiv.className = 'act-video-btn act-play-btn';
 		el.appendChild(el.pauseDiv)
 		el.appendChild(el.playDiv);
 		el.pauseDiv.hide();
 		el.oldHide = el.hide;
 		el.oldShow = el.show;
 		el.oldRemove = el.remove;
 		if(scale && scale !== 1){
 			TweenLite.set(el.pauseDiv,{ scaleX:scale, scaleY:scale});	
 			TweenLite.set(el.playDiv,{ scaleX:scale, scaleY:scale});
 		}	
 		el.css({opacity:0, scaleX:.85, scaleY:.85, display:'none', transformOrigin:"center center"});	

	 	el.remove = function(withFade)
	 	{
	 		if(withFade && withFade === true) TweenLite.to(this,.4,{ opacity:0, scaleX:.85, scaleY:.85, ease: Back.easeIn, onCompleteScope:this, onComplete:this.oldRemove});
	 		else this.oldRemove();
	 		return this;
	 	}


	 	el.play = function()
	 	{
	 		this.pauseDiv.hide();
	 		this.playDiv.show();
	 		this.hide();
	 		return this;
	 	}

	 	el.pause = function(animate)
	 	{
	 		if(animate !== undefined && animate === false){
	 			this.pauseDiv.hide();
		 		this.playDiv.show();
		 		this.oldShow();
		 		TweenLite.set(this,{ opacity:1, scaleX:1, scaleY:1});
	 		} else {
	 			this.pauseDiv.show();
		 		this.playDiv.hide();
		 		this.show(1, function(){
		 			this.pauseDiv.hide();
		 			this.playDiv.show();
		 		}, this);
	 		}
	 		return this;
	 	}

	 	el.show = function(alpha,func,scope)
	 	{
	 		//alert('videobtn show()');
	 		this.oldShow();
	 		TweenLite.to(this,.4,{opacity:alpha?alpha:1, ease: Quad.easeOut, onComplete:func, onCompleteScope: scope});
	 		//alert(this._gsTransform.scaleX);
	 		if(this._gsTransform.scaleX === '1')
	 		{
	 			TweenLite.to(this,.1,{ scaleX:.9, scaleY:.9, ease: Quad.easeIn, overwrite:0});
	 			TweenLite.to(this,.2,{ scaleX:1, scaleY:1, ease: Quint.easeOut, overwrite:0, delay:.2});
	 		}
	 		else
	 		{
	 			TweenLite.to(this,.4,{ scaleX:1, scaleY:1, ease: Back.easeOut, overwrite:0});
	 		}
	 		return this;
	 	}

	 	el.hide = function()
	 	{
	 		if(this.css('display') !== 'none') TweenLite.to(this,.4,{ opacity:0, scaleX:.85, scaleY:.85, ease: Back.easeIn, onComplete: this.oldHide, onCompleteScope: this});
	 		return this;
	 	}
	 	return el;
 	};


}());

/* ********* otp.VideoPreloader **************
* VideoPreloader
* dependencies: jquery
*/
(function() {
	"use strict";
 	window.scope.VideoPreloader = function(videoElement, height, playheadColor, loadlineColor, bgColor) 
 	{
 		var video = videoElement,
 			el = scope.NewComponent('div',{class:'v-load-div'}),
 			loadline = scope.NewComponent('div',{class:'v-loadline'}),
 			playhead = scope.NewComponent('div',{class:'v-playhead'}),
			playhead,
			loadline,
			pw, ct, b, i,
			progressUpdate;
		//
 		el.appendChild(loadline);
 		el.appendChild(playhead);
 		//
 		if(height){
 			el.css({height:height+em});
 			el.loadline({height:height+em});
 			el.playhead({height:height+em});
 		} 
 		if(playheadColor) playhead.css({backgroundColor:playheadColor}); 
		if(loadlineColor) loadline.css({backgroundColor:loadlineColor}); 
		if(bgColor) this.el.css({backgroundColor:bgColor}); 

 		el.addListeners = function()
	 	{
	 		video.addEventListener("timeupdate", onTimeUpdate);
	 		video.addEventListener("durationchange", onDurChange);	
	 	};

	 	el.removeListeners = function()
	 	{
	 		video.removeEventListener("timeupdate", onTimeUpdate);
	 		video.removeEventListener("durationchange", onDurChange);
	 		clearInterval(progressUpdate);
	 		progressUpdate = null;
	 	};

	 	function onDurChange(e) 
	 	{
	 		// using setInterval instead of listenr because "progress" event is not universal or part of currnet html5 video spec.
	 		playhead.css("width","0%");
	 		loadline.css("width","0%");
	 		if(progressUpdate) clearInterval(progressUpdate);
	 		progressUpdate = setInterval(onProgress, 99);
	 	};

	 	function onTimeUpdate(e) 
	 	{
	 		ct = video.currentTime;
	 		playhead.css("width",((ct / video.duration) * 100)+"%");
	 	};

	 	function onProgress(e) 
	 	{
			//Some Androids don't return a buffered range
	 		b = null;
	 		 for (i = 0; i < video.buffered.length; i++) {
	            b = video.buffered.end(i);
	        }
	        if(!b && ct>0) b = ct+2; // fake buffer when no buffered range is returned
	        pw = Math.round((b / video.duration) * 100);
	 		if(pw >= 100){
	 			pw = 100;
	 			clearInterval(progressUpdate);
	 			progressUpdate = null;
	 		};
			loadline.css("width",pw+"%");
			
	 	};
	 	return el;
 	};


}());

(function () {
	window.scope.Spinner = function ()
	{
		var el = scope.NewComponent('div',{class:'act-spinner'});
		el.oldHide = el.hide;
 		el.oldShow = el.show;
 		el.oldRemove = el.remove;
 		el.isOn = false;
 		el.ticker = null;
 		el.oldHide();
 		el.css({opacity:0});
 	
	 	el.remove = function(withFade)
	 	{
	 		if(withFade && withFade === true){
	 			this.hide(function(){
	 				this.ticker = cancelAF(this.ticker);
	 				this.oldRemove();
	 			},null,this);
	 		} else {
	 			this.oldRemove();
	 			this.ticker = cancelAF(this.ticker);
	 		}
	 		return this;
	 	}

	 	el.show = function(startFunc,args,scope)
	 	{
	 		if(!this.isOn)
	 		{
	 			this.oldShow();
		 		if(startFunc) startFunc.apply(scope || window, args || []);
		 		TweenLite.to(this,.4,{opacity:1, ease:Quad.easeOut });
		 		var ctx = this;
		 		this.ticker = requestAF(function(){onSpinnerTick(ctx)});
		 		this.isOn = true;
	 		}
	 		return this;
	 	}

	 	el.hide = function(endFunc,args,scope)
	 	{
	 		if(this.isOn || this.css("opacity") > 0)
	 		{
	 			this.isOn = false;
		 		TweenLite.to(this,.4,{opacity:0, ease:Quad.easeIn, onCompleteScope:this,
		 			onComplete: function(){
		 				this.ticker = cancelAF(this.ticker);
		 				this.oldHide();
		 				if(endFunc) endFunc.apply(scope || window, args || []);
		 			}
		 		});
	 		}
	 		return this;
	 	}
		return el;
	};

	function onSpinnerTick (el) 
	{
 		TweenLite.set(el,{rotation: '+= 11.25'});
 		el.ticker = requestAF(function(){onSpinnerTick(el)});
 	};

}());


/* ********* otp.Note **************
* Note made with css
* dependencies: tweenlite
*/
(function() {
	"use strict";
	/* Note params
	*	text:String;
	*	direction:String = "bottom";  (where the arrow points) = "top","bottom","left","right"
	*	position:Number = 2; (position of arrow ie left,center,right or top,center,bottom. 2 = center) = 1,2,3;
	*/
 	window.scope.Note = function(text,direction,position,isOn) 
 	{
 		var el = scope.NewComponent('div',{class:'act-note-div'});
 		el.note = scope.NewComponent('div',{class:'act-note'});
 		el.note.innerHTML = '<p class="act-note-text">'+text+'</p>';
 		el.appendChild(el.note);
 		el.isOn = isOn!==undefined?isOn:true;
 		el.oldHide = el.hide;
 		el.oldShow = el.show;
 		el.resize = true;
 		el.oldRemove = el.remove;
 		if(el.isOn) TweenLite.set(el,{transformOrigin:"center center"});
 		else TweenLite.set(el,{ opacity:0, scaleX:.9, scaleY:.9, transformOrigin:"center center"});

 		switch(direction)
 		{
 			case "top":
 				el.note.addClass("act-note-top");
 				break;
 			case "bottom":
 				el.note.addClass("act-note-bottom");
 				break;
 			case "left":
 				el.note.addClass("act-note-left");
 				break;
 			case "right":
 				el.note.addClass("act-note-right");
 				break;	
 			default:
 				el.note.addClass("act-note-bottom");
 				break;			
 		};

 		switch(position)
 		{
 			case 1:
 				if(direction === "top" || direction === "bottom") el.note.addClass("act-note-tb-1");
 				else if(direction === "left" || direction === "right") el.note.addClass("act-note-lr-1");
 				break;
 			case 2:
 				if(direction === "top" || direction === "bottom") el.note.addClass("act-note-tb-2");
 				else if(direction === "left" || direction === "right") el.note.addClass("act-note-lr-2");
 				break;
 			case 3:
 				if(direction === "top" || direction === "bottom") el.note.addClass("act-note-tb-3");
 				else if(direction === "left" || direction === "right") el.note.addClass("act-note-lr-3");
 				break;
 			default:
 				// default in css is 50% (2)
 				break;			
 		};	

	 	el.text = function(str)
	 	{
	 		if(str){
	 			this.note.innerHTML ='<p class="act-note-text">'+str+'</p>';
	 			return this;
	 		} else {
	 			return this.note.firstChild.innerHTML;
	 		}
	 			
	 	}

	 	el.remove = function(withFade)
	 	{
	 		this.isOn = false;
	 		if(withFade && withFade === true){
	 			TweenLite.to(this,.3,{ opacity:0, scaleX:.9, scaleY:.9, ease: Back.easeIn, onCompleteScope:this, onComplete:this.oldRemove});
	 		} else {
	 			this.oldRemove();
	 		}
	 		return this;
	 	}

	 	el.show = function(delay)
	 	{
	 		if(!this.isOn){
	 			this.isOn = true;
	 			TweenLite.to(this,.4,{ opacity:1, delay:delay||0, ease: Quad.easeOut});
	 			TweenLite.to(this,.5,{ scaleX:1, scaleY:1, delay:delay||0, ease: Back.easeOut, overwrite:0, onStartScope:this,
	 				onStart: function(){
	 					this.oldShow();
                		if(this.resize && this.parent()){
				 			this.style.width = this.note.style.width = (this.note.offsetWidth / parseFloat(window.getComputedStyle(document.body,null).getPropertyValue('font-size').replace('px',''))) + 'em';
				 		}
	                }
	            });
	 		}
	 		return this;
	 	}

	 	el.hide = function(delay)
	 	{
	 		if(this.isOn){
	 			this.isOn = false;
	 			TweenLite.to(this,.3,{ opacity:0, scaleX:.9, scaleY:.9, delay:delay||0, ease: Back.easeIn, onComplete: this.oldHide, onCompleteScope:this});
	 		}
	 		return this;
	 	}
	 	return el;
	 };

}());


(function () {
	window.scope.Alert = function (text,btnText,icon,removeOnOff)
	{
		var el = scope.NewComponent('div',{class:'act-alert box-shadow'});
		el.oldHide = el.hide;
 		el.oldShow = el.show;
 		el._data.remove = removeOnOff;
 		el._text = document.createElement('section');
 		el._text.innerHTML = text;
 		el._text.className = 'act-alert-txt';
 		el._okBtn = scope.UIBtn((btnText?btnText:'OK'),null,(icon?icon:'check'));
 		el.appendChild(el._text);
 		el.appendChild(el._okBtn);
 		el._offFunc = undefined;
        el._okFunc = undefined;
 		el.oldHide().css({alpha:0, scaleX:.8, scaleY:.8, transformOrigin:"center center"});
 		el.oldRemove = el.remove;
 	
 		function onOk(e)
        {
            e.data.context.hide(e);
            if(e.data.context._okFunc) e.data.context._okFunc.f.apply(e.data.context._okFunc.s || window, e.data.context._okFunc.a || []);
        }

	 	el.show = function(delay)
	 	{	
	 		this.oldShow();
            TweenLite.to(this,.5,{autoAlpha:1, scaleX:1, scaleY:1, delay:delay?delay:0, ease: Back.easeOut, onCompleteScope:this, onStartScope:this,
                onComplete: function(){
                    this._okBtn.on(scope.UP,{context:this},onOk);
                },
                onStart: function(){
                	TweenLite.set(this,{marginTop:-((this.clientHeight/2) / parseFloat(window.getComputedStyle(document.body,null).getPropertyValue('font-size').replace('px',''))) + 'em' });
                }
            });
            return this;
	 	}

	 	el.hide = function()
	 	{
	 		this._okBtn.off(scope.UP,onOk);
            TweenLite.to(this,.4,{autoAlpha:0, scaleX:.8, scaleY:.8, ease:Back.easeIn, onCompleteScope:this,
                onComplete: function(){
                    if(this._data.remove) this.remove(); else this.oldHide();
                    if(this._offFunc) this._offFunc.f.apply(this._offFunc.s || window, this._offFunc.a || []);
                }
            });
            return this;
	 	}

	 	el.remove = function(withFade)
	 	{
	 		this.isOn = false;
	 		this._okBtn.off(scope.UP,onOk);
	 		if(withFade && withFade === true){
            	TweenLite.to(this,.4,{autoAlpha:0, scaleX:.8, scaleY:.8, ease:Back.easeIn, onCompleteScope:this, onComplete:this.oldRemove});
	 		} else {
	 			this.oldRemove();
	 		}
	 		return this;
	 	}

	 	el.text = function(txt)
        {
            this._text.innerHTML = txt;
            return this;
        }

        el.setBtn = function(txt,icon)
        {
            this._okBtn.innerHTML = '<span class="btn-icon-sprite '+(icon?'btn-icon-'+icon:'')+'"></span>'+txt;
            return this;
        };

        el.reset = function()
        {
            this._offFunc = null;
            this._okFunc = null;
            this._text.innerHTML = '';
            this._okBtn.innerHTML = '<span class="btn-icon-sprite btn-icon-check"></span>OK';
            TweenLite.set(this,{alpha:0, scaleX:.8, scaleY:.8, display:'none'});
            return this;
        }

        el.offFunc = function(func,args,scp){this._offFunc = {f:func, a:args, s:scp}; return this};
        el.okFunc = function(func,args,scp){this._okFunc = {f:func, a:args, s:scp}; return this};
		return el;
	};

}());

(function () {
	window.scope.Confirm = function (text,removeOnOff)
	{
		var el = scope.NewComponent('div',{class:'act-alert box-shadow'});
		el.oldHide = el.hide;
 		el.oldShow = el.show;
 		el._data.remove = removeOnOff;
 		el._text = document.createElement('section');
 		el._form = scope.NewComponent('form');
 		el._text.innerHTML = text;
 		el._text.className = 'act-alert-txt';
 		el._yesBtn = scope.UIBtn('YES',null,'check');
		el._noBtn = scope.UIBtn('NO',null,'close');
		el.appendChild(el._form);
 		el._form.appendChild(el._text);
 		el._form.appendChild(el._yesBtn);
 		el._form.appendChild(el._noBtn);
 		el.oldHide().css({alpha:0, scaleX:.8, scaleY:.8, transformOrigin:"center center"});
 	

        function onYes(e)
        {
            if(!e.data.context._form) e.data.context.hide(e);
            if(e.data.context._yesFunc) e.data.context._yesFunc.f.apply(e.data.context._yesFunc.s || window, e.data.context._yesFunc.a || []);
        };

        function onNo(e)
        {
            e.data.context.hide(e);
            if(e.data.context._noFunc) e.data.context._noFunc.f.apply(e.data.context._noFunc.s || window, e.data.context._noFunc.a || []);
        };

        function onThird(e)
        {
            if(!e.data.context._form) e.data.context.hide(e);
            if(e.data.context._thirdFunc) e.data.context._thirdFunc.f.apply(e.data.context._thirdFunc.s || window, e.data.context._thirdFunc.a || []);
        };

        function preventDefault(e){if(e.preventDefault) return e.preventDefault(); else return false}

        el._addAttrToForm = function(s){
            this._form.setAttribute('btn-that-submitted',s);
        };

	 	el.show = function(delay)
	 	{	
	 		this.oldShow();
            TweenLite.to(this,.5,{autoAlpha:1, scaleX:1, scaleY:1, delay:delay?delay:0, ease: Back.easeOut, onCompleteScope:this, onStartScope:this,
                onComplete: function(){
	 				this._yesBtn.on(scope.UP,{context:this},onYes);
            		this._noBtn.on(scope.UP,{context:this},onNo);
            		if(this._thirdBtn) this._thirdBtn.on(scope.UP,{context:this},onThird);
                },
                onStart: function(){
                	TweenLite.set(this,{marginTop:-((this.clientHeight/2) / parseFloat(window.getComputedStyle(document.body,null).getPropertyValue('font-size').replace('px',''))) + 'em' });
                }
            });
            return this;
	 	}

	 	el.hide = function()
	 	{
	 		this._yesBtn.off(scope.UP,onYes);
            this._noBtn.off(scope.UP,onNo);
            if(this._thirdBtn) this._thirdBtn.off(scope.UP,onThird);
            TweenLite.to(this,.4,{autoAlpha:0, scaleX:.8, scaleY:.8, ease:Back.easeIn, onCompleteScope:this,
                onComplete: function(){
                    if(this._data.remove) this.remove(); else this.oldHide();
                    if(this._offFunc) this._offFunc.f.apply(this._offFunc.s || window, this._offFunc.a || []);
                }
            });
            return this;
	 	}

	 	el.text = function(txt)
        {
            this._text.innerHTML = txt;
            return this;
        }

        el.setBtns = function(obj1,obj2,obj3) //obj:{text:string, icon:string, color:string, href:string, func:function, params:array, scope:object}
        {
	    	if(obj1){
	    		var icon = obj1.icon || 'check', //(text,href,icon,color,inDiv,isOff) 
	                btn = obj3 ? (this._thirdBtn = scope.UIBtn(obj1.text,obj1.href,icon,obj1.color)) : this._yesBtn; 
	            this._setFunc = obj3 ? this.thirdFunc : this.yesFunc;
	            if(obj1.func) this._setFunc(obj1.func, obj1.params, obj1.scope);
	            if(this._thirdBtn) {
	            	this._form.insertBefore(this._thirdBtn, this._yesBtn);
	            } else {
	            	if(obj1.text) btn.text(obj1.text);
	            	if(obj1.icon) btn.icon(obj1.icon);
	            	if(obj1.color) btn.color(obj1.color);
	            	if(obj1.href) btn.href(obj1.href);
	            }
	            delete this._setFunc;
	            //console.log(obj1, icon);
	    	}
	    	if(obj2){
	    		var icon = obj3 ? 'check' : 'close', 
	                btn = obj3 ? this._yesBtn : this._noBtn;
	            this._setFunc = obj3 ? this.yesFunc : this.noFunc;     
	            if(obj2.func) this._setFunc (obj2.func, obj2.params, obj2.scope);
	            if(obj2.icon) btn.icon(obj2.icon);
	            if(obj2.text) btn.text(obj2.text);
	            if(obj2.color) btn.color(obj2.color);
	            if(obj2.href) btn.href(obj2.href);
	            delete this._setFunc;
	    	}
	        if(obj3){
	            var icon = obj3.icon || 'close';
	            if(obj3.func) this.noFunc(obj3.func, obj3.params, obj3.scope);
	            if(obj3.icon) this._noBtn.icon(obj3.icon);
	            if(obj3.text) this._noBtn.text(obj3.text);
	            if(obj3.color) this._noBtn.color(obj3.color);
	            if(obj3.href) this._noBtn.href(obj3.href);
	        }
	        if( (obj1.func || obj1.href) || (obj2 && (obj2.func || obj2.href)) || (obj3 && (obj3.func || obj3.href)) ) this._form.on('submit', preventDefault); 
            return this;
        };

        el.setForm = function(formID, func, doesThirdSubmit)
        {   
        	var ctx = this, attrObj = {form:formID, type:'submit', name:'yesBtn'};
        	this._form.id = formID;
        	this._form.off('submit', preventDefault);
            this._form.on('submit', function(e){
                func(e);
                ctx.hide(e);
                return preventDefault(e);
            });
            this._yesBtn.attr(attrObj);
            this.yesFunc(this._addAttrToForm, ['yesBtn'], this);
            attrObj.name ='noBtn';
            this._noBtn.attr(attrObj);
            this.noFunc(this._addAttrToForm, ['noBtn'], this);	
            if(this._doesThirdSubmit && this._thirdBtn){ 
            	attrObj.name ='thirdBtn';
                this._thirdBtn.attr(attrObj);
                this.thirdFunc(this._addAttrToForm, ['thirdBtn'], this);
            }
            return this;
        };

        el.reset = function()
        {
            this.offFunc(null,null,null);
            this.yesFunc(null,null,null);
        	this.noFunc(null,null,null);
            this._yesBtn.removeAttribute('form');
            this._yesBtn.removeAttribute('type');
        	this._yesBtn.innerHTML ='<span class="btn-icon-sprite check"></span>YES';
        	this._noBtn.innerHTML = '<span class="btn-icon-sprite close"></span>NO';
            this._yesBtn.off(scope.UP,onYes);
            this._noBtn.off(scope.UP,onNo);
            if(this._thirdBtn) this._thirdBtn.off(scope.UP,onThird);
            if(this._thirdBtn){
                this._form.removeChild(this._thirdBtn);
                this.thirdFunc(null,null,null);
                this._thirdBtn = null;
            }
            this._text.innerHTML = '';
            TweenLite.set(this,{alpha:0, scaleX:.8, scaleY:.8, display:'none'});
            return this;
        }

        el.offFunc = function(func,args,scp){this._offFunc = {f:func, a:args, s:scp}; return this};
        el.yesFunc = function(func,args,scp){this._yesFunc = {f:func, a:args, s:scp}; return this};
        el.noFunc = function(func,args,scp){this._noFunc = {f:func, a:args, s:scp}; return this};
        el.thirdFunc = function(func,args,scp){this._thirdFunc = {f:func, a:args, s:scp}; return this};
		return el;
	};

}());

(function () {
	"use strict";
	window.scope.MakeCuepointMedia = function(media, addListeners, tolerance)
	{
		var context = media,
			cuepoints = [],
			cpMode = "event",
			cpNav, cpIndex = 0,
			l = 0, n, i, navTimer,
			hasListeners,
			al = addListeners || true,
			isAndroid = (function () {
				if(window.navigator.platform && window.navigator.platform === 'Android') return true;
				else if(window.navigator.userAgent.toLowerCase().search('android') !== -1) return true;
				else if(window.navigator.appVersion.toLowerCase().search('android') !== -1) return true;
				else return false;
			}());
		//
		media.tolerance = tolerance || .3;
		//
		Object.defineProperty(media, 'hasListeners', {
		  	get: function() {return hasListeners},
		  	set: function() {throw new Error('CuepointMedia.hasListeners is a read only property.') }
		});
		Object.defineProperty(media, 'isCuepointMedia', {
		  	get: function() {return true},
		  	set: function() {throw new Error('CuepointMedia.isCuepointMedia is a read only property.') }
		});
		//	
		media.addCuepointListeners = function() 
	 	{
	 		//console.log('addCuepointListeners()')
	 		hasListeners = true;
			this.addEventListener("seeked", onMediaSeeked, false);
			// "seeked" event unreliable on Android add "seeking" listener to help out
			if(isAndroid) this.addEventListener("seeking", onMediaSeeked, false);
			this.addEventListener("playing", onMediaPlaying, false);
			if(!app.video.paused) requestAF(update);
			return this;
	 	};

	 	media.removeCuepointListeners = function()
	 	{
	 		//console.log('removeCuepointListeners()')
	 		hasListeners = false;
			this.removeEventListener("seeked", onMediaSeeked);
			this.removeEventListener("seeking", onMediaSeeked);
			this.removeEventListener("playing", onMediaPlaying);
			cancelAF(update);
			return this;

	 	};
		media.addCuepoint = function(time,kind,name,func,args,scope)
	 	{
	 		cuepoints.push({t:time, k:kind, id:name, f:func, a:args, s:scope, executed:false});
	 		cuepoints.sort(function(a,b) { return parseFloat(a.t) - parseFloat(b.t) } );
	 		l = cuepoints.length; //console.log(cuepoints);
	 		return this;
	 	};
	 	//
	 	media.seekCuepoint = function(name)
	 	{
	 		for(n=0; l>n; n++){
	 			if(cuepoints[n].id === name){
	 				//console.log('\t'+ cuepoints[n].id);
	 				if(cuepoints[n].k === "nav" || cuepoints[n].k === "both"){
	 					//console.log('\t\t'+ cuepoints[n].k);
	 					cpMode = "nav";
	 					if(this.currentTime >= cuepoints[n].t && this.currentTime < cuepoints[n].t + this.tolerance)
	 					{ 
	 						//console.log('seek: stay and execute');
							cuepoints[n].f.apply(cuepoints[n].s || context, cuepoints[n].a || []);
							cpMode = "event";
							requestAF(update);
	 					} else {
	 						//console.log('seek: set cpNav and change currentTime to '+cuepoints[n].t);
	 						cpNav = cuepoints[n];
	 						this.currentTime = cuepoints[n].t;
	 					}; 
		 				break;
	 				};
		 		};
	 		};
	 		return this;
	 	};
		//
		function update()
	 	{
	 		//console.log("CuepointMedia.update()", context.currentTime);
	 		if (context.paused || context.ended) return false;
			else if(l>0 && cpMode === "event"){
				for(i=0; l>i; i++){
					if (context.currentTime >= cuepoints[i].t && context.currentTime < cuepoints[i].t + context.tolerance){
						if((cuepoints[i].k === "event" || cuepoints[i].k === "both") && !cuepoints[i].executed){
							//console.log("on cp event", context.currentTime);
							//console.log("\tcuepoint: "+cuepoints[i].id, cuepoints[i].k, cuepoints[i].t, cuepoints[i].executed);
							cuepoints[i].f.apply(cuepoints[i].s || context, cuepoints[i].a || []);
							cuepoints[i].executed = true;
							var cp = cuepoints[i];
							TweenLite.delayedCall(.5,function(){cp.executed = false; });
						};
						break;
					};
	 			};
			};
			if(hasListeners) requestAF(update);
	 	};
	 	//
	 	function onNavTimer()
	 	{
	 		//console.log("CuepointMedia.onNavTimer()", context.currentTime);
	 		if(cpNav===null || cpNav===undefined){
	 			clearInterval(navTimer);
	 		} else {
	 			if (context.currentTime >= cpNav.t-.2 && context.currentTime < cpNav.t + context.tolerance){
		 			// on nav cuepoint
					//console.log("on cp nav", context.currentTime);
					//console.log("\tcuepoint: "+cpNav.id, cpNav.k, cpNav.t);
					if(app.loadAnim) app.loadAnim.hide();
					cpNav.f.apply(cpNav.s || context, cpNav.a || []);
					cpNav = null;
					clearInterval(navTimer);
					//TweenLite.delayedCall(.4,function(){cpMode = "event"});
					cpMode = "event";
					if(hasListeners) requestAF(update);
				};
	 		};
	 	};
	 	//
	 	function onMediaSeeked(e){if(cpMode === "nav" && cpNav !== null) navTimer = setInterval(onNavTimer,17)};
	 	//
	 	function onMediaPlaying(e){if(hasListeners) requestAF(update)};
	 	//
	 	if(al) media.addCuepointListeners();
	 	return media;
	 };
}());


